.invert {
  mix-blend-mode: difference;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes hideBlackBars {
  0% {
    height: 60px;
  }
  100% {
    height: 0px;
  }
}

.black-bar {
  animation-name: hideBlackBars;
  animation-delay: 5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: #000000;
  height: 60px;
  position: fixed;
  width: 100%;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
